<template>
  <v-app-bar
    dense
    color="primary"
    src="https://cube.community/main/tournament/bswc2022/pic/BG.png"
  >
    <v-img
      src="https://cdn.discordapp.com/attachments/643532738787541012/955873396837736498/Artboard_1_copy0.png"
      max-height="65"
      max-width="65"
      class="mt-8 mr-3"
    ></v-img>
    <v-app-bar-title
      ><span style="font-weight: 601">BSWC2022</span> Stream
      Host</v-app-bar-title
    >
    <v-spacer></v-spacer>

    <v-btn @click="dialogAnnouncements = true" icon>
      <v-icon>mdi-android-messages</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {};
</script>

<style></style>
