import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueSocketIO from "vue-socket.io";

Vue.config.productionTip = false;

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: process.env.VUE_APP_HOST
      ? process.env.VUE_APP_HOST
      : "http://localhost:3410",
  })
);

import ImageFallBack from "./fallback-directive";

Vue.directive("image-fall-back", ImageFallBack);

new Vue({
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
