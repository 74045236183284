<template>
  <v-app>
    <v-main>
      <v-progress-linear
        background-color="rgb(170, 112, 198)"
        color="#643a8c"
        :indeterminate="loadingBar"
        height="5"
      ></v-progress-linear>

      <AppBar></AppBar>
      <v-container>
        <v-row>
          <v-col cols="9">
            <v-row
              style="padding-right: 10px; padding-left: 10px; margin-top: 1rem"
            >
              <h1 style="margin-bottom: 0">Match Settings</h1>
            </v-row>

            <v-row v-if="tournament.bracket && tournament.bracket.teams">
              <v-col cols="6">
                <v-autocomplete
                  :items="tournament.bracket.teams"
                  item-text="displayName"
                  item-value="displayName"
                  v-model="team1Selected"
                  label="Team 1"
                ></v-autocomplete>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  :items="tournament.bracket.teams"
                  item-text="displayName"
                  item-value="displayName"
                  v-model="team2Selected"
                  label="Team 2"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-btn :disabled="loading" @click="setMatch" color="primary">
              Set Match
            </v-btn>

            <v-btn
              :disabled="matchData == null || tournament.taMatch"
              @click="setPlayers"
              style="margin-left: 5px"
              color="primary"
            >
              Set Players
            </v-btn>

            <v-btn
              :disabled="matchData == null"
              @click="giveRoles"
              color="primary"
              style="margin-left: 5px"
            >
              Give Discord Roles
            </v-btn>

            <v-btn @click="clearMatch" color="primary" style="margin-left: 5px">
              Clear Match
            </v-btn>

            <v-dialog v-model="pickingPodium" max-width="600px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="loading"
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  style="margin-left: 5px"
                >
                  Set Podium
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Setting the Podium</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="4">
                        <v-autocomplete
                          :items="tournament.bracket.teams"
                          item-text="displayName"
                          item-value="displayName"
                          v-model="podiumTeam1Selected"
                          label="1st"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          :items="tournament.bracket.teams"
                          item-text="displayName"
                          item-value="displayName"
                          v-model="podiumTeam2Selected"
                          label="2nd"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="4">
                        <v-autocomplete
                          :items="tournament.bracket.teams"
                          item-text="displayName"
                          item-value="displayName"
                          v-model="podiumTeam3Selected"
                          label="3rd"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="pickingPodium = false"> Close </v-btn>
                  <v-btn text @click="pickPodium"> Save </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-btn @click="loading = false" style="margin-left: 5px">
              Debug Loading
            </v-btn>

            <transition name="slide-fade" mode="out-in">
              <div v-if="matchData">
                <v-row
                  style="
                    padding-right: 10px;
                    padding-left: 10px;
                    margin-top: 1rem;
                  "
                >
                  <h1 style="margin-bottom: 0">Configured Match</h1>
                </v-row>

                <v-row>
                  <v-col cols="5">
                    <v-card
                      elevation="2"
                      class="bg-image"
                      :style="{
                        color: '#ffffff',
                        'border-radius': '0px',
                        'border-bottom': 'solid 4px #1E1E1E',
                        background:
                          'linear-gradient(to left, rgba(36, 36, 36, 0.93), rgb(33, 33, 33)) repeat scroll 0% 0%, rgba(0, 0, 0, 0) url(' +
                          this.matchData.p1.avatar +
                          ') no-repeat scroll 0% 0%',
                      }"
                    >
                      <v-card-title>
                        <img
                          alt="user"
                          :src="this.matchData.p1.avatar"
                          style="width: 42px"
                        />
                        <p class="ml-5 mt-2" style="font-size: 30px">
                          {{ this.matchData.p1.name }}
                        </p>
                      </v-card-title>
                      <v-card-text>
                        <div
                          v-for="player in this.matchData.p1.players"
                          :key="player.participantID"
                        >
                          <v-avatar size="24">
                            <img
                              alt="user"
                              v-image-fall-back
                              :src="
                                'https://new.scoresaber.com' + player.pictureUrl
                              "
                            />
                          </v-avatar>
                          <span style="margin-left: 10px">{{
                            player.name
                          }}</span
                          ><br />
                          <div
                            style="
                              border-left: 2px solid rgba(255, 255, 255, 0.5);
                              padding-left: 15px;
                              margin-top: 10px;
                              margin-bottom: 10px;
                            "
                          >
                            <i class="fab fa-twitch"></i> {{ player.streamUrl }}
                            <v-btn
                              color="primary"
                              x-small
                              @click="swapUnmuted(player.streamUrl)"
                              style="margin-left: 10px; margin-top: -5px"
                              >Set as Audio</v-btn
                            >
                            <v-btn
                              color="primary"
                              x-small
                              @click="refreshPlayer(player.streamUrl)"
                              style="margin-left: 10px; margin-top: -5px"
                              >Refresh Player</v-btn
                            >
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col
                    cols="2"
                    style="
                      text-align: center;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    "
                  >
                    <h1>vs</h1>
                  </v-col>
                  <v-col cols="5">
                    <v-card
                      elevation="2"
                      class="bg-image"
                      :style="{
                        color: '#ffffff',
                        'border-radius': '0px',
                        'border-bottom': 'solid 4px #1E1E1E',
                        background:
                          'linear-gradient(to left, rgba(36, 36, 36, 0.93), rgb(33, 33, 33)) repeat scroll 0% 0%, rgba(0, 0, 0, 0) url(' +
                          this.matchData.p2.avatar +
                          ') no-repeat scroll 0% 0%',
                      }"
                    >
                      <v-card-title>
                        <img
                          alt="user"
                          :src="this.matchData.p2.avatar"
                          style="width: 42px"
                        />
                        <p class="ml-5 mt-2" style="font-size: 30px">
                          {{ this.matchData.p2.name }}
                        </p>
                      </v-card-title>
                      <v-card-text>
                        <div
                          v-for="player in this.matchData.p2.players"
                          :key="player.participantID"
                        >
                          <v-avatar size="24">
                            <img
                              alt="user"
                              v-image-fall-back
                              :src="
                                'https://new.scoresaber.com' + player.pictureUrl
                              "
                            />
                          </v-avatar>
                          <span style="margin-left: 10px">{{
                            player.name
                          }}</span
                          ><br />
                          <div
                            style="
                              border-left: 2px solid rgba(255, 255, 255, 0.5);
                              padding-left: 15px;
                              margin-top: 10px;
                              margin-bottom: 10px;
                            "
                          >
                            <i class="fab fa-twitch"></i>
                            {{ player.streamUrl }}
                            <v-btn
                              color="primary"
                              x-small
                              @click="swapUnmuted(player.streamUrl)"
                              style="margin-left: 10px; margin-top: -5px"
                              >Set as Audio</v-btn
                            >
                            <v-btn
                              color="primary"
                              x-small
                              @click="refreshPlayer(player.streamUrl)"
                              style="margin-left: 10px; margin-top: -5px"
                              >Refresh Player</v-btn
                            >
                          </div>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

                <v-row
                  style="
                    padding-right: 10px;
                    padding-left: 10px;
                    margin-top: 1rem;
                    padding-bottom: 1rem;
                  "
                >
                  <h1 style="margin-bottom: 1rem">Maps</h1>
                  <v-dialog v-model="pickingMapPool" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="loading"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        small
                        style="margin-top: 1.35em; margin-left: 15px"
                      >
                        Set Map Pool
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Choose a Map Pool</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <v-autocomplete
                                :items="
                                  tournament.pools.pools.map(
                                    (pool) => pool.name
                                  )
                                "
                                v-model="poolNameSelected"
                                label="Map Pool"
                              ></v-autocomplete>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="pickingMapPool = false">
                          Close
                        </v-btn>
                        <v-btn text @click="pickMapPool"> Save </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="settingCustomMap" max-width="600px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="loading || !tournament.taMatch"
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        small
                        style="margin-top: 1.35em; margin-left: 15px"
                      >
                        Set Custom Map
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Set a Custom Map</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12">
                              <v-text-field
                                label="Map Key"
                                v-model="customMapKey"
                                @input="findCustomMap"
                              ></v-text-field>
                              <v-select
                                :items="customMapDiffs"
                                label="Difficulty"
                                v-model="customMapDiffPicked"
                                :disabled="customMapDiffs.length === 0"
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="settingCustomMap = false">
                          Close
                        </v-btn>
                        <v-btn
                          text
                          @click="setCustomMap(true)"
                          :disabled="!customMapObj || !customMapDiffPicked"
                        >
                          Set Map
                        </v-btn>
                        <v-btn
                          text
                          @click="setCustomMap(false)"
                          :disabled="!customMapObj || !customMapDiffPicked"
                        >
                          Set Map without transition
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-row
                    v-if="poolSelected && poolSelected.maps"
                    style="justify-content: left"
                  >
                    <v-col
                      v-for="map in pickedMaps"
                      :key="map.BeatSaver.key"
                      cols="12"
                      md="4"
                    >
                      <v-item>
                        <v-card
                          elevation="2"
                          class="bg-image"
                          :style="{
                            color: '#ffffff',
                            'border-radius': '0px',
                            'border-bottom': 'solid 4px #1E1E1E',
                            background:
                              'linear-gradient(to left, rgba(36, 36, 36, 0.93), rgb(33, 33, 33)) repeat scroll 0% 0%, rgba(0, 0, 0, 0) url(' +
                              map.cover_url +
                              ') no-repeat scroll 0% 0%',
                            'background-size': 'cover',
                          }"
                        >
                          <v-card-title>
                            <v-chip
                              :color="
                                diffsDict.filter(
                                  (diff) => diff.beatSaver === map.map_diff
                                )[0].color
                              "
                              small
                              style="position: absolute; right: 10px; top: 10px"
                            >
                              {{
                                diffsDict.filter(
                                  (diff) => diff.beatSaver === map.map_diff
                                )[0].short
                              }}
                            </v-chip>
                            <span style="font-size: 18px">
                              {{ map.BeatSaver.metadata.songAuthorName }}
                              <span
                                style="
                                  display: block;
                                  font-size: 22px;
                                  font-weight: 800;
                                "
                                >{{ map.BeatSaver.metadata.songRealName }}</span
                              >
                            </span>
                          </v-card-title>
                          <v-card-text>
                            Uploaded {{ map.BeatSaver.uploadedMoment }}<br />
                            Mapped by
                            {{ map.BeatSaver.metadata.realLevelAuthorName
                            }}<br />
                            Key: {{ map.map_key }}
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              text
                              @click="setMap(map, true)"
                              :disabled="!tournament.taMatch"
                            >
                              Select
                            </v-btn>
                            <v-btn
                              text
                              @click="setMap(map, false)"
                              :disabled="!tournament.taMatch"
                            >
                              Silent Select
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-item>
                    </v-col>
                  </v-row>
                </v-row>
              </div>
            </transition>
          </v-col>
          <v-col cols="3">
            <div style="position: fixed">
              <v-row style="padding-right: 10px; padding-left: 10px">
                <h1 style="margin-top: 20px; margin-bottom: 0">TA</h1>
              </v-row>
              <div v-if="!matchData">
                <h3 style="display: block">Connected</h3>
                <v-row
                  style="
                    padding-right: 10px;
                    padding-left: 10px;
                    margin-top: 10px;
                  "
                >
                  <v-tooltip
                    v-for="user in tournament.taUsers.filter(
                      (user) => user.client_type === 0
                    )"
                    :key="user.id"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        ><a :href="'https://scoresaber.com/u/' + user.user_id">
                          <v-avatar style="margin-right: 5px">
                            <img
                              :src="
                                'https://cdn.scoresaber.com/avatars/' +
                                user.user_id +
                                '.jpg'
                              "
                              :alt="user.name"
                            />
                          </v-avatar>
                        </a>
                      </span>
                    </template>
                    <span>{{ user.name }}</span>
                  </v-tooltip>
                </v-row>
              </div>

              <div v-if="matchData">
                <h3 style="display: block">{{ matchData.p1.name }}</h3>
                <v-row
                  style="
                    padding-right: 10px;
                    padding-left: 10px;
                    padding-bottom: 1rem;
                    margin-top: 10px;
                  "
                >
                  <v-tooltip
                    v-for="player in matchData.p1.players"
                    :key="player.participantID"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        ><a
                          :href="
                            'https://scoresaber.com/u/' + player.scoresaber
                          "
                        >
                          <v-avatar
                            style="margin-right: 5px; overflow: initial"
                            :class="{
                              'user-not-in-ta': !tournament.taUsers.find(
                                (user) => user.user_id === player.scoresaber
                              ),
                              'user-in-match':
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ),
                              gray:
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ) &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ).download_state == 0,
                              orange:
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ) &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ).download_state == 1,
                              green:
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ) &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ).download_state == 2,
                              red:
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ) &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ).download_state == 3,
                              playing:
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ) &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ).play_state == 1,
                            }"
                          >
                            <img
                              v-image-fall-back
                              :src="
                                'https://new.scoresaber.com' + player.pictureUrl
                              "
                              :alt="player.name"
                            />
                          </v-avatar>
                        </a>
                      </span>
                    </template>
                    <span>{{ player.name }}</span>
                  </v-tooltip>
                </v-row>

                <h3 style="display: block">{{ matchData.p2.name }}</h3>
                <v-row
                  style="
                    padding-right: 10px;
                    padding-left: 10px;
                    margin-top: 10px;
                    padding-bottom: 1.5rem;
                  "
                >
                  <v-tooltip
                    v-for="player in matchData.p2.players"
                    :key="player.participantID"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on"
                        ><a
                          :href="
                            'https://scoresaber.com/u/' + player.scoresaber
                          "
                        >
                          <v-avatar
                            style="margin-right: 5px; overflow: initial"
                            :class="{
                              'user-not-in-ta': !tournament.taUsers.find(
                                (user) => user.user_id === player.scoresaber
                              ),
                              'user-in-match':
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ),
                              gray:
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ) &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ).download_state == 0,
                              orange:
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ) &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ).download_state == 1,
                              green:
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ) &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ).download_state == 2,
                              red:
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ) &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ).download_state == 3,
                              playing:
                                tournament.taMatch &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ) &&
                                tournament.taMatch.associated_users.find(
                                  (user) => user.user_id === player.scoresaber
                                ).play_state == 1,
                            }"
                          >
                            <img
                              v-image-fall-back
                              :src="
                                'https://new.scoresaber.com' + player.pictureUrl
                              "
                              :alt="player.name"
                            />
                          </v-avatar>
                        </a>
                      </span>
                    </template>
                    <span>{{ player.name }}</span>
                  </v-tooltip>
                </v-row>

                <template v-if="tournament.taMatch">
                  <v-btn @click="closeTAMatch" color="danger"
                    >Close Match</v-btn
                  >
                </template>
                <template v-else>
                  <v-btn @click="createTAMatch" color="primary"
                    >Create Match</v-btn
                  > </template
                ><br />
                <v-btn
                  @click="returnToMenu"
                  :disabled="!tournament.taMatch"
                  color="primary"
                  class="mt-2"
                  >Return to Menu</v-btn
                ><br />
                <!-- <v-btn
                  @click="playMap"
                  :disabled="!tournament.taMatch"
                  color="primary"
                  class="mt-2"
                  >Play Map</v-btn
                > -->

                <h3 v-if="loadedMap" style="display: block" class="mb-4 mt-3">
                  Loaded Map
                </h3>
                <v-row v-if="loadedMap">
                  <v-card
                    elevation="2"
                    class="bg-image"
                    :style="{
                      color: '#ffffff',
                      'border-radius': '0px',
                      'border-bottom': 'solid 4px #1E1E1E',
                      background:
                        'linear-gradient(to left, rgba(36, 36, 36, 0.93), rgb(33, 33, 33)) repeat scroll 0% 0%, rgba(0, 0, 0, 0) url(' +
                        loadedMap.cover_url +
                        ') no-repeat scroll 0% 0%',
                      'background-size': 'cover',
                      'min-width': '100%',
                    }"
                  >
                    <v-card-title>
                      <v-chip
                        :color="
                          diffsDict.filter(
                            (diff) => diff.beatSaver === loadedMap.map_diff
                          )[0].color
                        "
                        small
                        style="position: absolute; right: 10px; top: 10px"
                      >
                        {{
                          diffsDict.filter(
                            (diff) => diff.beatSaver === loadedMap.map_diff
                          )[0].short
                        }}
                      </v-chip>
                      <span style="font-size: 18px">
                        {{ loadedMap.BeatSaver.metadata.songAuthorName }}
                        <span
                          style="
                            display: block;
                            font-size: 22px;
                            font-weight: 800;
                          "
                          >{{ loadedMap.map_name }}</span
                        >
                      </span>
                    </v-card-title>
                    <v-card-text>
                      Mapped by
                      {{ loadedMap.map_mapper }}<br />
                      Key: {{ loadedMap.map_key }}
                    </v-card-text>
                  </v-card>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>

        <v-dialog v-model="multipleMatches" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Multiple Matches Found</span>
            </v-card-title>
            <v-card-text v-if="tournament.bracket">
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12">
                    <v-radio-group
                      v-model="multipleMatchesSelected"
                      label="Please select the correct match below:"
                    >
                      <v-radio
                        v-for="n of tournament.bracket.bracket.filter(
                          (match) =>
                            match.p1.name === team1Selected &&
                            match.p2.name === team2Selected
                        )"
                        :key="n.id"
                        :label="`${n.round >= 0 ? 'Winners' : 'Losers'} Round ${
                          n.round >= 0 ? n.round + 1 : n.round * -1
                        }`"
                        :value="n.id"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-bt text @click="multipleMatches = false">Close</v-bt>
              <v-btn text @click="setMatch">Select</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="selectingPlayers" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Player Selection</span>
            </v-card-title>
            <v-card-text>
              <v-container v-if="team1Info && team2Info">
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-select
                      :items="team1Info.teamMembers"
                      :label="this.team1Selected + ' Players:'"
                      multiple
                      item-text="name"
                      v-model="team1SelectedPlayers"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-select
                      :items="team2Info.teamMembers"
                      :label="this.team2Selected + ' Players:'"
                      multiple
                      item-text="name"
                      v-model="team2SelectedPlayers"
                    >
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="selectingPlayers = false">Close</v-btn>
              <v-btn text @click="confirmPlayers">Select</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <v-snackbar v-model="showErr">
        {{ errorText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="showErr = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="showInfo">
        {{ infoText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="green" text v-bind="attrs" @click="showInfo = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "./components/AppBar.vue";
import Moment from "moment";
import Axios from "axios";

export default {
  name: "App",
  data() {
    return {
      tournament: {
        bracket: null,
        pools: null,

        taUsers: [],
        taMatch: null,

        currentMap: null,
        currentMapPool: null,
        currentMatch: null,
      },

      errorText: "",
      showErr: false,

      infoText: "",
      showInfo: false,

      team1Selected: null,
      team2Selected: null,

      podiumTeam1Selected: null,
      podiumTeam2Selected: null,
      podiumTeam3Selected: null,

      team1SelectedPlayers: [],
      team2SelectedPlayers: [],

      settingCustomMap: false,
      customMapKey: "",
      customMapObj: null,
      customMapDiffs: [],
      customMapDiffPicked: null,
      customMapDebounce: null,

      selectingPlayers: false,

      team1Info: null,
      team2Info: null,

      matchNumber: null,
      matchData: null,

      multipleMatches: false,
      multipleMatchesSelected: null,

      pickingMapPool: false,
      pickingPodium: false,

      loading: false,

      poolNameSelected: "",
      poolSelected: null,
      picksAndBansOverlay: [],

      diffsDict: [
        {
          color: "#8f48db",
          name: "Expert+",
          beatSaver: "expertPlus",
          otherBeatSaver: "ExpertPlus",
          short: "EX+",
        },
        {
          color: "#bf2a42",
          name: "Expert",
          beatSaver: "expert",
          otherBeatSaver: "Expert",
          short: "EX",
        },
        {
          color: "#f07c00",
          name: "Hard",
          beatSaver: "hard",
          otherBeatSaver: "Hard",
          short: "H",
        },
        {
          color: "#59b0f4",
          name: "Normal",
          beatSaver: "normal",
          otherBeatSaver: "Normal",
          short: "N",
        },
        {
          color: "#3cb371",
          name: "Easy",
          beatSaver: "easy",
          otherBeatSaver: "Easy",
          short: "E",
        },
      ],
    };
  },
  sockets: {
    data(res) {
      this.tournament[res.type] = res.data;
    },
    discordError(res) {
      this.showError(res.text);
    },
    discordInfo(res) {
      this.showInformation(res.text);
    },
  },
  computed: {
    loadingBar() {
      return this.loading || !this.tournament.bracket || !this.tournament.pools;
    },
    loadedMap() {
      return this.tournament.taMatch &&
        this.tournament.taMatch.selected_level &&
        this.tournament.currentMap
        ? this.tournament.currentMap
        : null;
    },
    pickedMaps() {
      return this.poolSelected.maps.filter((map) => {
        return !this.tournament.currentMatch.bans.find(
          (ban) => ban.map_key === map.map_key
        );
      });
    },
  },
  methods: {
    async giveRoles() {
      this.$socket.emit("giveRoles");
    },
    async clearMatch() {
      this.matchData = null;
      this.$socket.emit("clearCurrentMatch");
    },
    async findCustomMap() {
      this.customMapObj = null;
      clearTimeout(this.customMapDebounce);

      this.customMapDebounce = setTimeout(async () => {
        let key = this.customMapKey;
        try {
          let beatSaver = await this.getBeatSaverMap(key);
          let ver = beatSaver.versions.find((v) => v.state === "Published");
          if (!ver) {
            this.customMapDebounce;
            return this.showError("This map isn't published.");
          }
          this.customMapObj = beatSaver;

          this.customMapDiffs = ver.diffs.map((map) => map.difficulty);
        } catch (e) {
          this.showError("This map doesn't exist.");
        }
      }, 1000);
    },
    async setCustomMap(trans) {
      let BeatSaver = this.customMapObj;
      let ver = BeatSaver.versions.find((v) => v.state === "Published");
      let diff = ver.diffs.find(
        (diff) => diff.difficulty === this.customMapDiffPicked
      );

      let mapObj = {
        map_id: -1,
        map_key: this.customMapKey,
        map_hash: ver.hash,
        map_name: BeatSaver.metadata.songName,
        map_artist: BeatSaver.metadata.songAuthorName,
        map_mapper: BeatSaver.metadata.levelAuthorName,
        map_diff: this.diffsDict.find(
          (d) => d.otherBeatSaver === diff.difficulty
        ).beatSaver,
        display_diff: this.diffsDict.find(
          (d) => d.otherBeatSaver === diff.difficulty
        ).name,
        note_count: diff.notes,
        cover_url: ver.coverURL,
        BeatSaver,
      };

      this.setMap(mapObj, trans);
      this.settingCustomMap = false;
    },
    swapUnmuted(url) {
      this.$socket.emit("unmuteTwitch", { url });
    },
    refreshPlayer(url) {
      this.$socket.emit("refreshPlayer", { url });
    },
    updateMatchInfo() {
      if (this.tournament.taMatch) {
        this.matchNumber = this.tournament.taMatch.match_number;
        this.matchData = this.tournament.taMatch;
      }
    },
    async setMatch() {
      this.loading = true;
      if (
        this.tournament.bracket.bracket.filter(
          (match) =>
            match.p1.name === this.team1Selected &&
            match.p2.name === this.team2Selected
        ).length > 1
      ) {
        if (this.multipleMatches === true && this.multipleMatchesSelected) {
          this.multipleMatches = false;
          this.matchNumber = this.tournament.bracket.bracket.findIndex(
            (match) => match.id === this.multipleMatchesSelected
          );
          this.multipleMatchesSelected = null;
        } else {
          this.multipleMatchesSelected = null;
          this.multipleMatches = true;
          return;
        }
      } else {
        this.matchNumber = this.tournament.bracket.bracket.findIndex(
          (match) =>
            match.p1.name === this.team1Selected &&
            match.p2.name === this.team2Selected
        );
      }

      if (
        this.matchNumber < -1 ||
        !this.tournament.bracket.bracket[this.matchNumber]
      ) {
        this.showError("Match doesn't exist.");
        this.loading = false;
        return;
      }

      console.log(this.tournament.bracket.bracket);
      console.log(this.matchNumber);

      this.team1Info = this.tournament.bracket.teams.filter(
        (team) => team.displayName === this.team1Selected
      )[0];
      this.team2Info = this.tournament.bracket.teams.filter(
        (team) => team.displayName === this.team2Selected
      )[0];

      this.team1SelectedPlayers = [];
      this.team2SelectedPlayers = [];

      if (this.matchNumber > -1) {
        if (this.tournament.bracket.bracket[this.matchNumber]) {
          this.matchData = null;
          let matchData = this.tournament.bracket.bracket[this.matchNumber];

          matchData.p1.team = this.team1Info;
          matchData.p1.players = [];
          matchData.p2.team = this.team2Info;
          matchData.p2.players = [];

          this.matchData = matchData;
          this.loadedMatchNumber = this.matchNumber;
          this.$socket.emit("setData", {
            type: "currentMatch",
            data: this.matchData,
          });

          this.poolNameSelected = matchData.poolName;
          await this.pickMapPool();
        } else {
          this.showError("Match doesn't exist.");
        }
      } else {
        this.showError("Match doesn't exist.");
      }

      this.loading = false;
    },
    async setPlayers() {
      this.selectingPlayers = true;
    },
    async confirmPlayers() {
      this.loading = true;
      this.selectingPlayers = false;
      console.log("pog");

      if (this.matchNumber > -1) {
        if (this.tournament.bracket.bracket[this.matchNumber]) {
          this.matchData = null;
          let matchData = this.tournament.bracket.bracket[this.matchNumber];

          matchData.unmuted_player = "";
          matchData.p1.team = this.team1Info;
          matchData.p1.players = [
            ...this.team1Info.teamMembers.filter(
              (member) => member.name === this.team1SelectedPlayers[0]
            ),
            ...this.team1Info.teamMembers.filter(
              (member) => member.name === this.team1SelectedPlayers[1]
            ),
            ...this.team1Info.teamMembers.filter(
              (member) => member.name === this.team1SelectedPlayers[2]
            ),
            ...this.team1Info.teamMembers.filter(
              (member) => member.name === this.team1SelectedPlayers[3]
            ),
          ];
          matchData.p2.team = this.team2Info;
          matchData.p2.players = [
            ...this.team2Info.teamMembers.filter(
              (member) => member.name === this.team2SelectedPlayers[0]
            ),
            ...this.team2Info.teamMembers.filter(
              (member) => member.name === this.team2SelectedPlayers[1]
            ),
            ...this.team2Info.teamMembers.filter(
              (member) => member.name === this.team2SelectedPlayers[2]
            ),
            ...this.team2Info.teamMembers.filter(
              (member) => member.name === this.team2SelectedPlayers[3]
            ),
          ];
          this.matchData = matchData;
          this.loadedMatchNumber = this.matchNumber;
          this.$socket.emit("setData", {
            type: "currentMatch",
            data: this.matchData,
          });
        } else {
          this.showError("Match doesn't exist.");
        }
      } else {
        this.showError("Match doesn't exist.");
      }
      this.loading = false;
    },
    createTAMatch() {
      this.$socket.emit("createTAMatch");
    },
    closeTAMatch() {
      this.$socket.emit("closeTAMatch");
    },
    returnToMenu() {
      this.$socket.emit("returnToMenu");
    },
    playMap() {
      this.$socket.emit("playMap");
    },
    setMap(map, transition = true) {
      this.$socket.emit("setMap", {
        map,
        transition,
      });
    },
    showError(msg) {
      this.errorText = msg;
      this.showErr = true;
    },
    showInformation(msg) {
      this.infoText = msg;
      this.showInfo = true;
    },
    async getBeatSaverMap(key) {
      return (
        await Axios.get(
          "https://overlay.ccwc.jiveoff.fr/api/getBeatSaverMap?key=" + key
        )
      ).data;
    },
    async pickPodium() {
      this.pickingPodium = false;
      this.$socket.emit("setData", {
        type: "currentPodium",
        data: {
          team1: this.podiumTeam1Selected,
          team2: this.podiumTeam2Selected,
          team3: this.podiumTeam3Selected,
        },
      });
    },
    async pickMapPool() {
      this.loading = true;
      this.pickingMapPool = false;
      this.poolSelected = {};
      this.picksAndBansOverlay = [];
      if (this.poolNameSelected || this.poolNameSelected.length > 0) {
        let poolSel = this.tournament.pools.pools.filter(
          (pool) => pool.name === this.poolNameSelected
        )[0];
        let erroredMaps = [];
        let successfulMaps = [];
        for (let map of poolSel.maps) {
          try {
            map.BeatSaver = await this.getBeatSaverMap(map.map_key);
            map.BeatSaver.uploadedMoment = Moment(
              map.BeatSaver.uploaded
            ).fromNow();
            if (map.BeatSaver.metadata.songAuthorName.length > 20) {
              map.BeatSaver.metadata.songAuthorName =
                map.BeatSaver.metadata.songAuthorName.substring(0, 20) + "…";
            }
            if (map.BeatSaver.metadata.songName.length > 24) {
              map.BeatSaver.metadata.songRealName =
                map.BeatSaver.metadata.songName.substring(0, 24) + "…";
            } else {
              map.BeatSaver.metadata.songRealName =
                map.BeatSaver.metadata.songName;
            }
            if (map.BeatSaver.metadata.levelAuthorName.length > 24) {
              map.BeatSaver.metadata.realLevelAuthorName =
                map.BeatSaver.metadata.levelAuthorName.substring(0, 24) + "…";
            } else {
              map.BeatSaver.metadata.realLevelAuthorName =
                map.BeatSaver.metadata.levelAuthorName;
            }

            successfulMaps.push(map);
          } catch (e) {
            console.log(e);
            erroredMaps.push(
              map.BeatSaver.key + " (" + map.BeatSaver.metadata.songName + ") "
            );
            map = null;
          }
        }
        if (erroredMaps.length > 0) {
          this.showError("Trouble loading these maps: " + erroredMaps);
        }
        poolSel.maps = successfulMaps;
        this.poolSelected = poolSel;
        this.$socket.emit("setData", {
          type: "currentMapPool",
          data: this.poolSelected,
        });
        this.poolSelected.image = null;
      }
      this.loading = false;
    },
  },
  components: { AppBar },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500&display=swap");

body,
.v-application {
  font-family: "Red Hat Display", sans-serif !important;
  font-size: 1.2rem;
}

.user-not-in-ta {
  opacity: 0.2;
}

.user-in-match::after {
  display: block;
  content: " ";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.user-in-match.gray:not(.playing)::after {
  border: 1px solid rgb(137, 145, 137);
  background: rgb(137, 145, 137);
}

.user-in-match.orange:not(.playing)::after {
  border: 1px solid rgb(224, 145, 55);
  background: rgb(224, 145, 55);
}

.user-in-match.green:not(.playing)::after {
  border: 1px solid rgb(55, 224, 55);
  background: rgb(55, 224, 55);
}

.user-in-match.red:not(.playing)::after {
  border: 1px solid rgb(218, 61, 61);
  background: rgb(218, 61, 61);
}

.user-in-match.playing::after {
  background-image: url("https://cdn.discordapp.com/emojis/762905450349461534.gif?size=96&quality=lossless") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 24px;
  height: 24px;
}
</style>
